
export default {
    name: 'couponChoose',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            skeleton: {                 // 骨架屏
                visible: false,
            },
            currency: {},               // 货币
            tab: {                      // 导航
                active: 1,
            },
            volume: {                   // 通知
                visible: true,
            },
            coupon: {                   // 优惠券
                info: {},               // 缓存的优惠券
                list: [],               // 优惠券列表
                canList: [],            // 可使用优惠券
                availableList: [],      // 失效优惠券
            },
            placeProductInfo: {},       // 下单商品
            params: {                   // 参数
                couponId: '',           // 优惠券id
                orderItems: [],
                spuIds: []
            },
            tabCompo: {
                list: []
            }
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
        this.setParamsData()
        this.getCouponAjax()
    },
    methods: {
        // 获取优惠券
        getCouponAjax() {
            this.$api.coupon.getChooseCoupon({
                orderItems: this.params.orderItems,
                spuIds: this.params.spuIds,
            }).then(response => {
                const res = response.result || {};
                this.coupon.canList = res.canUse || []
                this.coupon.availableList = res.invalid || []
                this.setCouponData()
            })
        },
        // 设置参数
        setParamsData() {
            this.placeProductInfo.forEach(item => {
                this.params.orderItems.push({
                    quantity: item.quantity,
                    spuId: item.spuId,
                    skuId: item.skuId,
                    goodsType: item.goodsType,
                })
                this.params.spuIds.push(item.spuId);
            })
        },
        // 设置优惠券数据
        setCouponData() {
            if(this.tab.active == 1) {
                this.coupon.list = this.coupon.canList
            } else {
                this.coupon.list = this.coupon.availableList
            }
            const freeCouponTip = [`FREE SHIPPING`, `STANDARD SHIPPING`, `EXPRESS SHIPPING`];
            this.coupon.list.forEach(item => {
                item.freeShippingType = 1
                item.threshold = ''
                item.maxThreshold = ''
                let suitTitle = [];
                let suitDesc = [];
                if(item.suitType == 1 || !item.suitSteps) {
                    item.suitSteps = []
                }
                // 阶梯
                item.suitSteps.forEach(c => {
                    if(item.type == 1) {
                        suitTitle.push(`${c.displayDiscountValue}%`)
                    } else {
                        suitTitle.push(`${this.$price(c.displayDiscountValue)}`)
                    }
                    if(item.suitType == 3) {
                        suitDesc.push(`${c.displayCondition}`)
                    } else {
                        suitDesc.push(`${this.$price(c.displayCondition)}`)
                    }
                })
                item.rsPercent = suitTitle.join('/')
                item.rsSuitDesc = suitDesc.join('/')
                // 非阶梯
                if(item.suitType == 1) {
                    if(item.type == 1) {
                        item.rsPercent = `${item.percent}% ${this.$translate('OFF')}`
                    } else {
                        item.rsPercent = `${this.$price(item.percent / 100)}`
                    }
                }
                if(item.suitType == 1) {
                    item.rsSuit = `${this.$translate('For all products')}`
                } else if(item.suitType == 2) {
                    item.rsSuit = `${this.$translate('For orders over')} ${item.rsSuitDesc}`
                } else {
                    item.rsSuit = `${this.$translate('For orders over')} ${item.rsSuitDesc} ${this.$translate('products')}`
                }
                item.rsTime = `*${this.$dayjs(item.gmtStart).format('YYYY-MM-DD')}-${this.$dayjs(item.gmtExpired).format('YYYY-MM-DD')}`
                if(item.displayDeduction) {
                    item.maxThreshold = `${this.$translate('For all products')} - ${this.$price(item.displayDeduction)}${this.$translate('max')}`
                }
                // 免邮提示
                item.shippingTip = ''
                if(item.type == 3 && item.freeShippingType) {
                    item.shippingTip = `${this.$translate('Shipping Only For')}: ${this.$translate(freeCouponTip[item.freeShippingType])}`
                }
            })
        },
        // tab change
        changeTab() {
            this.setCouponData()
        },
        // 优惠券选择
        handleCoupon(obj) {
            if(this.tab.active == 1) {
                this.params.couponId = obj.couponId
                this.$storage.set('order/coupon', obj)
                this.$fnApi.debounce(() => {
                    this.$router.back()
                }, 100)
            }
        },
        // 提示点击
        handleTip() {
            this.$Dialog.confirm({
                message: `${this.$translate('Only one coupon can be used per order')}.`,
                confirmButtonText: this.$translate('OK'),
                confirmButtonColor: '#333',
                showCancelButton: false
            }).then(() => {
            }).catch(() => {})
        },
        // 通知
        handleVolume() {
            this.volume.visible = false
        },
        // 返回
        handleBack() {
            this.$router.back()
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('Coupons'),
                keywords: this.$translate('Coupons'),
                description: this.$translate('Coupons'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
        // 初始化
        initialSet() {
            this.currency = this.$storage.get('currency') || {}
            this.placeProductInfo = this.$storage.get('order/placeProduct') || []
            this.coupon.info = this.$storage.get('order/coupon') || {}
            this.params.couponId = this.coupon.info.couponId
            this.tabCompo.list = [{
                title: this.$translate('Available'),
                name: 1
            }, {
                title: this.$translate('Unavailable'),
                name: 2
            }]
        }
    }
}
