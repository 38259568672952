
export default {
    name: 'CouponSection',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            currency: {},
            tab: {
                id: 1,
                list: []
            },
            coupon: {
                expired: [],
                available: [],
                list: []
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
        this.getCouponAjax()
    },
    methods: {
        // 获取coupon列表
        getCouponAjax() {
            this.$api.coupon.getCouponList().then(response => {
                const res = response.result || {
                    expired: [],
                    available: []
                };
                this.coupon.expired = res.expired
                this.coupon.available = res.available
                this.setData()
            })
        },
        setData() {
            const freeCouponTip = [`FREE SHIPPING`, `STANDARD SHIPPING`, `EXPRESS SHIPPING`];
            const nowStamp = new Date().getTime();
            this.coupon.list = this.coupon[['available', 'expired'][this.tab.id - 1]]
            this.coupon.list.forEach(item => {
                let suitTitle = [];
                let suitDesc = [];
                if(item.suitType == 1 || !item.suitSteps) {
                    item.suitSteps = []
                }
                // 阶梯
                item.suitSteps.forEach(c => {
                    if(item.type == 1) {
                        suitTitle.push(`${c.displayDiscountValue}%`)
                    } else {
                        suitTitle.push(`${this.$price(c.displayDiscountValue)}`)
                    }
                    if(item.suitType == 3) {
                        suitDesc.push(`${c.displayCondition}`)
                    } else {
                        suitDesc.push(`${this.$price(c.displayCondition)}`)
                    }
                })
                item.rsPercent = suitTitle.join('/')
                item.rsSuitDesc = suitDesc.join('/')
                // 非阶梯
                if(item.suitType == 1) {
                    if(item.type == 1) {
                        item.rsPercent = `${item.percent}%`
                    } else {
                        item.rsPercent = `${this.$price(item.percent / 100)}`
                    }
                }
                if(item.suitType == 1) {
                    item.rsSuit = `${this.$translate('For all products')}`
                } else if(item.suitType == 2) {
                    item.rsSuit = `${this.$translate('For orders over')} ${item.rsSuitDesc}`
                } else {
                    item.rsSuit = `${this.$translate('For orders over')} ${item.rsSuitDesc} ${this.$translate('products')}`
                }
                // 免邮提示
                item.shippingTip = ''
                if(item.type == 3 && item.freeShippingType) {
                    item.shippingTip = `${this.$translate('Shipping Only For')}: ${this.$translate(freeCouponTip[item.freeShippingType])}`
                }
                item.rsTime = `*${this.$dayjs(item.gmtStart).format('YYYY-MM-DD')}-${this.$dayjs(item.gmtExpired).format('YYYY-MM-DD')}`
                item.rsClass = ['CouponSection-available', 'CouponSection-expired'][this.tab.id - 1]
                item.disabled = this.tab.id == 2
                item.rsTag = []
                // 不能使用提示
                item.disabledBtnText = ''
                if(this.tab.id != 1 && (item.haveUse || item.expiredStamp < nowStamp)) {
                    const disabledBtnText = item.haveUse ? this.$translate('Used') : this.$translate('Expired');
                    item.rsTag = [{
                        color: '#B7B7B7',
                        text: disabledBtnText
                    }]
                }
                // 标签
                const reason = item.reason || 1; // 1- new 新优惠券 2-即将过期 3-已经适用 4-已过期
                const tagText = ['NEW', 'Expire Soon'][reason - 1];
                const tagColor = this.tab.id == 1 ? ['#96BD2C', '#F8981C'][reason - 1] : '#B7B7B7';
                if([1, 2].includes(item.reason)) {
                    item.rsTag = [{
                        color: tagColor,
                        text: this.$translate(tagText || '')
                    }]
                }
            })
        },
        changeTab() {
            this.setData()
        },
        // 跳转
        jumpWish(obj) {
            this.$router.push({
                name: 'CouponProduct',
                query: {
                    coupon_id: obj.couponId,
                    type: 1
                }
            })
        },
        // 初始化
        initialSet() {
            this.currency = this.$storage.get('currency') || {}
            this.tab.list = [{
                title: this.$translate('Available'),
                id: 1,
            }, {
                title: this.$translate('Expired'),
                id: 2,
            }]
        }
    },
}
