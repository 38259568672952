
export default {
    name: 'CreditCard',
    props: {},
    data() {
        return {
            card: {
                list: []
            },
            params: {}
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.getCardAjax()
    },
    methods: {
        // 获取coupon列表
        getCardAjax() {
            this.$api.user.getCardList().then(response => {
                const res = response.result || [];
                this.card.list = res
            })
        },
        deleteCardAjax() {
            this.$api.user.deleteCard(this.params).then(response => {
                this.getCardAjax()
            })
        },
        // 删除
        handleDelete(obj) {
            this.$Dialog.confirm({
                message: this.$translate('Are you sure you to delete this card'),
                confirmButtonText: this.$translate('No'),
                cancelButtonText: this.$translate('Yes'),
                cancelButtonColor: '#999999',
                confirmButtonColor: '#333'
            }).then(() => {
            }).catch(() => {
                this.params = {
                    id: obj.id
                }
                this.deleteCardAjax()
            });
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('My credit card'),
                keywords: this.$translate('My credit card'),
                description: this.$translate('My credit card'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
    },
}
