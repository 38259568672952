
export default {
    name: 'CouponHead',
    props: {},
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        jumpPolicy() {
            this.$router.push({
                name: 'Policy',
                query: {
                    id: '1679453569161DibQ8Se3'
                }
            })
        }
    },
}
