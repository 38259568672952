import { render, staticRenderFns } from "./index.vue?vue&type=template&id=76dcf7ad&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=76dcf7ad&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76dcf7ad",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Head: require('/home/php1/m-nuxt/components/Head.vue').default,YfnImage: require('/home/php1/m-nuxt/components/YfnImage.vue').default,YfnIcon: require('/home/php1/m-nuxt/components/YfnIcon.vue').default})
