
export default {
    name: 'couponDiscount',
    components: {},
    async asyncData({ $api }) {
        const response = await $api.coupon.getCouponConfig();
        const res = response.result || {};
        return {
            coupon: res
        }
    },
    props: {},
    data() {
        return {
            coupon: {},
            copyCode: [],
            skeleton: true,
            email: '',
            hasSubscribe: true,
            hasLogin: false,
            tips: '',
            ishas: 1,
            coupenlist: [],
            language:'en_US'
        }
    },
    computed: {},
    watch: {},
    head() {
        return this.setHead()
    },
    created() {},
    mounted() {
        this.language = this.$storage.get('language').identify
        this.skeleton = false
    },
    methods: {
        handleBack() {
            window.history.back()
        },
        inputEmail() {
            this.checkSubscribe()

        },
        checkSubscribe() { // 订阅校验
            let pattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
            this.tips = ''
            if (!this.email) {
                this.tips = this.$translate('Please enter your email address')
            }
            if (!pattern.test(this.email)) {
                this.tips = this.$translate('Invalid email address')
            }
        },
        handleSubscribe() {
            this.checkSubscribe()
            if (this.tips) {
                return
            }
            this.subscribeAjax()
        },
        // 邮件订阅
        subscribeAjax() { // 订阅接口
            this.$api.help.setSubscribe({
                data: JSON.stringify({ 
                    email: this.email,
                    subscribeSource:'coupon'
                })
            }).then(response => {
                const res = response.result || {};
                this.hasSubscribe = false
                this.ishas = res.result == false ? 2 : 1
            })
        },
        // copy
        copyBtn(v) {
            this.copyCode = [v]
            this.$fnApi.copy.initial(v)
        },
        handleCode() {
            const receiveCoupons = this.coupon.receiveCoupon.receiveCoupons;
            if(!this.copyCode.length) {
                this.copyCode = [receiveCoupons[0].couponId]
            }
            const code = this.copyCode[0] || '';
            this.$router.push({
                name: 'CouponProduct',
                query: {
                    coupon_id: code,
                    type: 1,
                    from: 'discount'
                }
            })
        },
        // active
        handleActive(obj) {
            this.$router.push({
                name: 'Activity-Tmpl',
                params: {
                    seoUrl: obj.seoUrl
                },
                query: {
                    activityId: obj.activityId
                }
            })
        },
        // category
        handleCategory(obj) {
            this.$router.push({
                name: 'CategoryDetail-Seo',
                params: {
                    code: obj.categoryCode,
                    seoUrl: obj.seoUrl
                }
            })
        },
        // bag
        handleBag() {
            this.$router.push({
                name: 'Bag',
            })
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.coupon.seoTitle,
                keywords: this.coupon.seoRemarks,
                description: this.coupon.seoRemarks,
                image: this.coupon.appCover
            });
            return {
                ...head,
            }
        },
    },
}
