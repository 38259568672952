import { render, staticRenderFns } from "./CouponHead.vue?vue&type=template&id=b52b66cc&scoped=true&"
import script from "./CouponHead.vue?vue&type=script&lang=js&"
export * from "./CouponHead.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b52b66cc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnIcon: require('/home/php1/m-nuxt/components/YfnIcon.vue').default,Head: require('/home/php1/m-nuxt/components/Head.vue').default})
