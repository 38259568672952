

export default {
    name: 'CouponProduct',
    components: {},
    props: {},
    data() {
        return {
            title: '',
            status: false,
            checked: false,
            visible: false,
            query: {},
            params: {                // 参数
                spuIds: ''
            },
            headCompo: {             // head组件配置
                hasBack: true
            },
            stickyComo: {
                hasNav: true,
                hasSort: true,
                hasFilter: true,
                hasCloseSticky: true,
                stickyTag: '.Head-box',
                data: {}
            },
            filter: {         // 筛选结果
                num: 0,
                mergeSort: '',
                params: {}
            },
            listCompo: {             // list组件配置
                list: [],
                options: {
                    hasMore: true,
                    hasConcat: true,
                }
            },
            page: {                   // 分页
                pageSize: 20,
                pageNum: 1,
                totalPage: 1
            }
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {
        process.client && this.initialSet()
    },
    mounted() {
        this.getCouponProductAjax()
    },
    methods: {
        // 获取优惠券可用商品
        getCouponProductAjax() {
            this.$api.coupon.getCouponProduct({
                ...this.page,
                id: this.query.coupon_id || '',
                sortColumn: this.filter.mergeSort,
                needFilter: true,
                ...this.filter.params,
            }).then(response => {
                const res = response.result || {};
                this.page.totalPage = res.totalPage
                this.listCompo.list = res.list || []
                // 筛选
                this.stickyComo.data = res.filter || {}
                this.stickyComo.data.total = res.total
            })
        },
        setDeleteWishAjax() { // 移除收藏商品
            this.$api.user.setDeleteCollect({
                spuIds: this.params.spuIds,
                skuIds: this.params.skuIds
            }).then(response => {
                const res = response.result || {};
                this.getCollectAjax()
            })
        },
        // 设置状态
        handleStatus() {
            this.status = !this.status
            !this.visible && (this.status = false)
        },
        // 删除收藏
        handleDelete() {
            if (!this.checked) {
                this.$Toast(this.lang('Please Choose'))
                return
            }
            let spuIdArr = [];
            let skuIdArr = []
            this.listCompo.list.forEach(item => {
                spuIdArr.push(item.spuId)
                item.skuId && skuIdArr.push(item.skuId)
            })
            this.params.spuIds = spuIdArr.join(',')
            this.params.skuIds = skuIdArr.join(',')
            this.listCompo.options.hasConcat = false
            this.setDeleteWishAjax()
        },
        // 滚动至顶部
        setScrollTo() {
            this.$VueScrollTo.scrollTo('body')
        },
        // 筛选
        emitChangeFilter(obj) { // filter change
            this.filter.mergeSort = obj.options.mergeSort
            this.filter.params = obj.filterResult.params
            this.listCompo.options.hasConcat = false
            this.page.pageNum = 1
            this.getCouponProductAjax()
            this.setScrollTo()
        },
        // emit
        emitBottomLoading(e, callBack) { // 触底加载
            const hasNext = this.page.pageNum < this.page.totalPage;
            if (e.isIntersecting && hasNext) {
                this.page.pageNum += 1
                this.listCompo.options.hasConcat = true
                this.getCouponProductAjax()
            }
            callBack(!hasNext)
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('Coupons Items'),
                keywords: this.$translate('Coupons Items'),
                description: this.$translate('Coupons Items'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
        // 初始化
        initialSet() {
            this.title = this.$translate('Coupons Items')
            this.query = this.$route.query || {}
        }
    },
}
